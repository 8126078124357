import {
  Address,
  ChartDataV3,
  Currency,
  NetworkConfigV3,
  PoolItem,
  TopCryptosToken,
  TopCryptosTotals,
  TradeType,
  TransactionModel,
  UserWalletCategory,
} from '../model'
import { SET_ACTIVE_TRADE_TYPE, SetActiveTradeTypeAction } from '../reducers/activeTradeType'
import { CurrencyAction, SET_CURRENCY } from '../reducers/currency'
import { SET_DATA_CHART, SetDataChartAction } from '../reducers/dataChart'
import { SET_DATA_CHART_FULL, SetDataChartFullAction } from '../reducers/dataChartFull'
import { AuthAction, SET_IS_AUTH } from '../reducers/isAuth'
import { MobileAction, SET_IS_MOBILE } from '../reducers/isMobile'
import { PaidUserPlanAction, SET_IS_PAID_USER_PLAN } from '../reducers/isPaidUserPlan'
import {
  NETWORKS_CONFIG_LOADED,
  NetworksConfigLoadedAction,
  SET_NETWORKS_CONFIG,
  SetNetworksConfigAction,
} from '../reducers/networksConfig'
import {
  INIT_IS_ONBOARDING_VISIBLE,
  InitIsOnboardingVisibleAction,
  SET_IS_ONBOARDING_VISIBLE,
  SetIsOnboardingVisibleAction,
} from '../reducers/onboarding'
import {
  RESET_POOL_ACTIVITY,
  ResetPoolActivityAction,
  SET_POOL_ACTIVITY,
  SetPoolActivityAction,
} from '../reducers/poolActivity'
import { SET_POOLS, SetPoolsAction } from '../reducers/pools'
import {
  SET_LOADING_TOKENS_TOP_CRYPTOS,
  SET_LOADING_TOTALS_TOP_CRYPTOS,
  SET_TOKENS_TOP_CRYPTOS,
  SET_TOTALS_TOP_CRYPTOS,
  SetLoadingTokensTopCryptosAction,
  SetLoadingTotalsTopCryptosAction,
  SetTokensTopCryptosAction,
  SetTotalsTopCryptosAction,
} from '../reducers/topCryptos'
import {
  RESET_TRADING_HISTORY,
  ResetTradingHistory,
  SET_TRADING_HISTORY,
  SetTradingHistory,
  TradeOrigin,
} from '../reducers/tradingHistory'
import {
  TxActionTypes,
  TxnCancelAction,
  TxnConfirmedAction,
  TxnErrorAction,
  TxnResetAction,
  TxnStartAction,
  TxnSubmittedAction,
  TxnUpdate,
  TxnUpdateAction,
} from '../reducers/txn'
import {
  SetWalletAddressAction,
  SetWalletCategoryAction,
  UserActions,
} from '../reducers/userReducer'

export const setCurrency = (currency: Currency): CurrencyAction => ({
  type: SET_CURRENCY,
  currency,
})

export const startTxn = (payload: TxnUpdate): TxnStartAction => ({
  type: TxActionTypes.START_TXN,
  payload,
})

export const resetTxn = (payload: TxnUpdate): TxnResetAction => ({
  type: TxActionTypes.RESET_TXN,
  payload,
})

export const errorTxn = (payload: TxnUpdate): TxnErrorAction => ({
  type: TxActionTypes.ERROR_TXN,
  payload,
})

export const cancelTxn = (payload: TxnUpdate): TxnCancelAction => ({
  type: TxActionTypes.CANCEL_TXN,
  payload,
})

export const updateTxn = (payload: TxnUpdate): TxnUpdateAction => ({
  type: TxActionTypes.UPDATE_TXN,
  payload,
})

export const submitTxn = (payload: TxnUpdate): TxnSubmittedAction => ({
  type: TxActionTypes.TX_VERIFICATION_SUBMITTED,
  payload,
})

export const confirmTxn = (payload: TxnUpdate): TxnConfirmedAction => ({
  type: TxActionTypes.TX_VERIFICATION_COMPLETE,
  payload: payload,
})

export const setIsMobile = (isMobile: boolean): MobileAction => ({
  type: SET_IS_MOBILE,
  isMobile,
})

export const setIsPaidUserPlan = (isPaidUserPlan: boolean): PaidUserPlanAction => ({
  type: SET_IS_PAID_USER_PLAN,
  isPaidUserPlan,
})

export const setTradingHistory = (
  currentTradingHistory?: TransactionModel[],
  origin: TradeOrigin = 'all'
): SetTradingHistory => ({
  type: SET_TRADING_HISTORY,
  currentTradingHistory,
  origin,
})

export const setPoolActivity = (
  currentPoolActivity?: TransactionModel[]
): SetPoolActivityAction => ({
  type: SET_POOL_ACTIVITY,
  currentPoolActivity,
})

export const resetTradingHistory = (): ResetTradingHistory => ({
  type: RESET_TRADING_HISTORY,
})

export const resetPoolActivity = (): ResetPoolActivityAction => ({
  type: RESET_POOL_ACTIVITY,
})

export const setChartsData = (dataChart: ChartDataV3): SetDataChartAction => ({
  type: SET_DATA_CHART,
  dataChart,
})

export const setChartsDataFull = (dataChartFull: ChartDataV3): SetDataChartFullAction => ({
  type: SET_DATA_CHART_FULL,
  dataChartFull,
})

export const setActiveTradeType = (activeTradeType: TradeType): SetActiveTradeTypeAction => ({
  type: SET_ACTIVE_TRADE_TYPE,
  activeTradeType,
})

export const setPools = (pools: PoolItem[]): SetPoolsAction => ({
  type: SET_POOLS,
  pools,
})

export const setNetworksConfig = (networksConfig: NetworkConfigV3[]): SetNetworksConfigAction => ({
  type: SET_NETWORKS_CONFIG,
  payload: networksConfig,
})

export const networksConfigLoaded = (isLoaded: boolean): NetworksConfigLoadedAction => ({
  type: NETWORKS_CONFIG_LOADED,
  payload: isLoaded,
})

export const setIsAuth = (isAuth: boolean): AuthAction => ({
  type: SET_IS_AUTH,
  isAuth,
})

export const setTokensTopCryptos = ({
  tokens,
}: {
  tokens: TopCryptosToken[]
}): SetTokensTopCryptosAction => ({
  type: SET_TOKENS_TOP_CRYPTOS,
  payload: { tokens },
})

export const setLoadingTotalsTopCryptos = ({
  totalsLoading,
}: {
  totalsLoading: boolean
}): SetLoadingTotalsTopCryptosAction => ({
  type: SET_LOADING_TOTALS_TOP_CRYPTOS,
  payload: { totalsLoading },
})

export const setTotalsTopCryptos = ({
  totals,
}: {
  totals: TopCryptosTotals
}): SetTotalsTopCryptosAction => ({
  type: SET_TOTALS_TOP_CRYPTOS,
  payload: { totals },
})
export const setLoadingTokensTopCryptos = ({
  tokensLoading,
}: {
  tokensLoading: boolean
}): SetLoadingTokensTopCryptosAction => ({
  type: SET_LOADING_TOKENS_TOP_CRYPTOS,
  payload: { tokensLoading },
})

export const setIsOnboardingVisible = (isVisible: boolean): SetIsOnboardingVisibleAction => ({
  type: SET_IS_ONBOARDING_VISIBLE,
  isVisible,
})

export const initIsOnboardingVisible = (): InitIsOnboardingVisibleAction => ({
  type: INIT_IS_ONBOARDING_VISIBLE,
})

export const setWalletCategory = (
  walletCategory?: UserWalletCategory
): SetWalletCategoryAction => ({
  type: UserActions.setWalletCategory,
  walletCategory,
})

export const setWalletAddress = (
  address?: Address | null,
  provider?: string
): SetWalletAddressAction => ({
  type: UserActions.setWalletAddress,
  payload: {
    address,
    provider,
  },
})

export { setGlobalLoader, setReloadRefresh } from './loaderActions'
export { fetchSettings, initSettings, updateSettings } from './settingsActions'
